import { createReducer, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import {
  setDiscountProduct,
  setExperiments,
  setProducts,
  setQuestions,
} from './actions';
import { IFunnelState } from './types';

const products = createReducer<IFunnelState['products']>(
  [],
  reduce(setProducts, set),
);
const questions = createReducer<IFunnelState['questions']>(
  [],
  reduce(setQuestions, set),
);
const experiments = createReducer<IFunnelState['experiments']>(
  null,
  reduce(setExperiments, set),
);
const discount_product = createReducer<IFunnelState['discount_product']>(
  null,
  reduce(setDiscountProduct, set),
);

export default combineReducers<IFunnelState>({
  products,
  questions,
  experiments,
  discount_product,
});
