import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { getQueryParams } from 'utils/location';
import { useSelector } from 'react-redux';

import { AppState } from 'state/types';

export const useRouter = () => {
  const { code } = useSelector((s: AppState) => s.user);
  const { pathname } = useLocation();

  const path = /\/$/.test(pathname) ? pathname.slice(0, -1) : pathname;

  const pushLocation = (
    pathname: string,
    query?: string | null,
    state?: object,
  ) => {
    const params = getQueryParams();
    const modifiedQuery =
      (!!query && (params ? `&${query}` : `?${query}`)) || '';
    const path = pathname + params + modifiedQuery;
    navigate(path, state);
  };

  const pathReplace = (path: string) =>
    path.replace(/\/lead/, '').replace(/\/payments/, '');

  return {
    goToLanding: () => pushLocation('/'),
    goToQuiz: (step?: number) => pushLocation('/quiz', `question=${step || 1}`),
    goToEmail: () => pushLocation('/email'),
    goToSummary: () => pushLocation('/summary'),
    goToCheckout: () => pushLocation('/checkout'),
    goToLead: () => pushLocation(`${pathReplace(path)}/lead`),
    goToPayments: () => pushLocation(`${pathReplace(path)}/payments`),
    goToSuccess: () => pushLocation(`/success`),
    goToError: () => pushLocation(`/error`),
    goToNotFound: () => pushLocation('/404'),
    closeModal: () => pushLocation(pathReplace(path)),
    goToTerms: () => pushLocation('/terms-policy'),
    goBack: () => navigate(-1),
    goToLogin: () => pushLocation('/login'),
  };
};
