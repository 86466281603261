import React from 'react';
import styled from 'styled-components';
import { FlexWrapper, H3, Small } from '@kilornd/modules-wrapper/es/components';
import { tablet } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import {
  convertHexToRGB,
  green,
  grey,
  neutral500,
  primary,
  primary50,
  primaryDark,
  white,
} from 'styles/colors';

const STEPS = ['Select Plan', 'Payment', 'Receipt'];
const textAlignments = ['left', 'center', 'right'];

interface Props {
  step: number;
}

export const StepProgressBar: React.FC<Props> = ({ step }) => {
  const totalSteps = STEPS?.length || 0;
  const progress = ((step - 1) * 100) / (totalSteps - 1);

  return (
    <Wrapper>
      <LineWrapper>
        <Line width={progress} />
      </LineWrapper>
      <FlexWrapper justifyContent="space-between" margin="0 1rem">
        {STEPS?.map((name, index) => {
          const i = index + 1;
          const isActive = step === i || step > i;

          return (
            <Step key={name}>
              <Small
                color={isActive ? primaryDark : neutral500}
                fontWeight={isActive ? 700 : 400}
                textAlign={textAlignments[index]}
              >
                {name}
              </Small>
            </Step>
          );
        })}
      </FlexWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto 2rem;
`;

const Step = styled.div`
  flex: 1;
`;

const LineWrapper = styled.div`
  height: 0.25rem;
  background-color: ${primary50};
  border-radius: 0.25rem;
  margin: 0 1rem 0.625rem;
`;

const Line = styled.div<{ width: number }>`
  width: ${({ width }) => width || '0'}%;
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: ${green};
`;

const Bubble = styled.div<{ active: boolean; done: boolean }>`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 1.5rem;
  background-color: ${({ done }) => (done ? primary : white)};
  border: ${({ active, done }) =>
    active || done
      ? `2px solid ${primary}`
      : '2px solid rgba(11, 19, 23, 0.2)'};
  border-radius: 50%;
`;
