import React, { StrictMode } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { configStore } from 'state/store';
import { MetaTags, withApp } from 'components';
import { Banners, Footers, Modals, Navigations } from 'layouts';
import { theme } from 'styles/theme';
import { almostWhite } from 'styles/colors';

const { store, persistor } = configStore();

export const wrapRootElement = ({ element }: { element: any }) => {
  const Providers: React.FC = ({ children }) => (
    <StrictMode>
      <Helmet
        title="Quit Smoking by Removing Cravings | ZeroSmokes"
        style={[
          {
            cssText: `
            *, *:after, *:before {
              padding: 0;
              margin: 0;
              box-sizing: border-box;
            }
            
            button,
            input,
            optgroup,
            select,
            textarea {
              font-family: inherit;
            }

            html {
              font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              text-size-adjust: 100%;
              text-rendering: optimizeLegibility;
            }
            
            body {
              margin: 0;
              overflow: hidden auto;
              background-color:${almostWhite};
            }

            a {
              text-decoration: none;
            }

            .disable-scrolling {
              height: 100%;
              overflow: hidden;
            }
          `,
          },
        ]}
      />
      <MetaTags />
      <Provider store={store}>{children}</Provider>
    </StrictMode>
  );

  if (typeof window === 'undefined') {
    return <Providers>{withApp(element)}</Providers>;
  }

  return (
    <Providers>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Banners />
          <Navigations />
          {withApp(element)}
          <Modals />
          <Footers />
        </ThemeProvider>
      </PersistGate>
    </Providers>
  );
};
