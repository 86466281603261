import React from 'react';
import styled from 'styled-components';
import { Select } from '@kilornd/modules-wrapper/es/components';

import { convertHexToRGB, grey } from 'styles/colors';
import { Svg } from 'components/images/Svg';
import { IQuestionOption } from 'state/funnel/types';

interface Props extends IQuestionOption {
  onClick: () => void;
  isSelected?: boolean;
  isSelectable?: boolean;
}

export const OptionCard: React.FC<Props> = ({
  label,
  description,
  custom,
  onClick,
  isSelectable = false,
  isSelected,
}) => {
  const { icon_size = 0, icon } = custom || {};

  return (
    <SelectStyled
      title={label}
      subtitle={description}
      custom={custom}
      icon={icon && <Svg src={icon} width={icon_size} height={icon_size} />}
      onClick={onClick}
      isSelectable={isSelectable}
      isSelected={isSelected}
    />
  );
};

const SelectStyled = styled(Select)`
  box-shadow: ${({ isSelected }) =>
    !isSelected ? `0 0 0 0.063rem ${convertHexToRGB(grey, 0.16)}` : ''};
  border-radius: 0.5rem;
`;
