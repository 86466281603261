import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContentFrameSmall,
  FlexWrapper,
  H1,
  H3,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { AppState } from 'state/types';
import { updateSummary } from 'state/user/effects';

import { Image, LoaderPercentage, Svg } from 'components';
import { blue, neutral400, primary, primaryDark } from 'styles/colors';
import { calculateSummaryData } from 'utils/summary';

const ITEMS = [
  'Analyzing your results',
  'Evaluating your answers',
  'Adjusting your preferences',
  'Looking for best quitting tips',
  'Creating your action plan',
];

export const Loading = () => {
  const dispatch = useDispatch();
  const { isMobile } = useQuery();
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const { quiz_answers: answers } = useSelector((s: AppState) => s.user);

  useEffect(() => {
    const summaryData = calculateSummaryData({
      age: String(answers['age']),
      years_of_smoking: String(answers['years_of_smoking']),
      smoking_expenses: String(answers['smoking_expenses']),
      triggers: String(answers['triggers']),
      quitting_concerns: String(answers['quitting_conerns']),
    });
    dispatch(updateSummary(summaryData));
  }, []);

  useEffect(() => {
    if (currentIndex < ITEMS.length) {
      setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 900);
    }
  }, [currentIndex]);

  return (
    <Wrapper>
      <FlexWrapper
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          src="cigarette"
          maxWidth={isMobile ? '100%' : '22rem'}
          margin="0 0 1rem"
        />
        <H1 color={blue}>
          <LoaderPercentage />
        </H1>
        <ProgressBar>
          <Progress />
        </ProgressBar>
        <ContentFrameSmall>
          <H3 textAlign="center" margin="0 0 1.5rem">
            Calculating your results
          </H3>
          <FlexWrapper flexDirection="column" alignItems="center">
            {ITEMS.map((item, index) => (
              <ListItem key={index}>
                <FlexWrapper
                  justifyContent="flex-start"
                  alignItems="center"
                  margin="0 0 0.5rem"
                  flexWrap="wrap"
                >
                  {index < currentIndex ? (
                    <Svg
                      src="check"
                      color={primary}
                      height="1.5rem"
                      width="1.5rem"
                    />
                  ) : (
                    <Svg
                      src="dots"
                      color={neutral400}
                      height="1.5rem"
                      width="1.5rem"
                    />
                  )}

                  <Regular
                    margin="0 0 0 1rem"
                    color={index < currentIndex ? primaryDark : neutral400}
                  >
                    {item}
                  </Regular>
                </FlexWrapper>
              </ListItem>
            ))}
          </FlexWrapper>
        </ContentFrameSmall>
      </FlexWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 2rem;
  height: 100vh;
  background-size: cover;
`;

const ProgressBar = styled.div`
  width: 22rem;
  height: 0.375rem;
  margin: 0 0 1.5rem;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 0.25rem;

  @media ${mobile} {
    max-width: 100%;
    margin: 0 0 2.5rem;
  }
`;

const progressAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const Progress = styled.div`
  width: 0;
  height: inherit;
  background: ${blue};
  transition: width 0.2s;
  animation: ${progressAnimation} 6000ms ease-in;
  animation-fill-mode: forwards;
  border-radius: 0.25rem;
`;

const ListItem = styled.li`
  list-style: none;
  max-width: 16.25rem;
  width: 100%;
`;
