import { ITheme } from '@kilornd/modules-wrapper/es/types/theme';

import { error, primary, textBlack } from './colors';

export const theme: ITheme = {
  colors: {
    primary: primary,
    text: textBlack,
    error,
  },
  typographies: {
    h1: {
      font_weight: 600,
    },
    h2: {
      font_weight: 600,
    },
    h3: {
      font_weight: 600,
    },
  },
};
