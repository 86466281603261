export enum ENVs {
  'production' = 'production',
  'development' = 'development',
}

export const NAME = 'ZeroSmokes';
const ENV =
  process.env.NODE_ENV === 'production' ? ENVs.production : ENVs.development;
const GTM_TAG = process.env.REACT_APP_GTM_TAG || 'GTM-K8X6LP5';
const API_TOKEN =
  process.env.REACT_APP_API_TOKEN || '3cb77f9d-2117-4002-986e-17443a5927c2';
const BASE_URL =
  process.env.REACT_APP_API_URL || 'https://rnd.kilohealthservices.com/api/';
const SENTRY_DNS =
  process.env.REACT_APP_SENTRY_DNS ||
  'https://69d5103b345f488da20205d1d93f40bb@o153093.ingest.sentry.io/5411566';

const configs = {
  [ENVs.development]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
  },
  [ENVs.production]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
  },
};

export const config = configs[ENV];
