import styled from 'styled-components';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { black, convertHexToRGB } from 'styles/colors';

export const SectionWrapper = styled.div<{ hasBackground?: boolean }>`
  display: block;
  margin: 3rem 0;

  ${({ hasBackground }) =>
    hasBackground &&
    `  {
      padding: 2.5rem 0;
      background: ${convertHexToRGB(black, 0.08)};
    }
  `}

  @media ${mobile} {
    margin: 2.5rem 0;
  }
`;
