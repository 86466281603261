import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '.';
import { AppState } from 'state/types';
import { fetchUser } from 'state/user/effects';
import { useLocation } from '@reach/router';

interface Props {
  component: React.ComponentType<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

export const PrivateRoute: React.FC<Props> = React.memo(
  ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const { code } = useSelector((s: AppState) => s.user);

    const params = new URLSearchParams(search);
    const codeParam = pathname.split('/')?.pop();
    const pathCode = params.get('code') || codeParam;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fetchUserData = async () => {
        await dispatch(fetchUser());
        setIsLoading(false);
      };

      if (pathCode && code !== pathCode) {
        fetchUserData();
      } else {
        setIsLoading(false);
      }
    }, []);

    return (
      <Route
        {...rest}
        render={props => {
          if (isLoading) {
            return <Loader />;
          } else if (code) {
            return <Component {...props} />;
          }
          return <Redirect to="/" />;
        }}
      />
    );
  },
);

PrivateRoute.displayName = 'Private Route';
