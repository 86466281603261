import React from 'react';
import { Router } from '@reach/router';

import { Route } from 'components';
import { FooterDefault } from './FooterDefault';

export const Footers: React.FC = () => (
  <Router primary={false}>
    {Route(
      [
        '/privacy-policy',
        '/terms-of-services',
        '/contacts',
        '/downsell-info',
        '/error',
        '/checkout',
        '/summary',
      ],
      FooterDefault,
    )}
  </Router>
);
