import styled, { keyframes } from 'styled-components';

import { primary } from 'styles/colors';

interface ISpinner {
  size?: string;
  margin?: string;
  color?: string;
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div<ISpinner>`
  width: ${({ size }) => size || '80px'};
  height: ${({ size }) => size || '80px'};
  margin: ${({ margin }) => margin || '0'};
  border: 8px solid #ffffff;
  border-top: 8px solid ${({ color }) => color || primary};
  border-left: 8px solid ${({ color }) => color || primary};
  border-right: 8px solid ${({ color }) => color || primary};
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;
