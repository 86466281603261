export const convertHexToRGB = (hex: string, tint?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (tint) {
    return `rgba(${r}, ${g}, ${b}, ${tint})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const primary = '#0EAD69';
export const blue = '#4FB0C6';
export const accent = '#FFD23F';
export const error = '#EE4266';
export const warning = '#540D6E';
export const textBlack = '#252525';
export const black = '#000000';
export const white = '#ffffff';

export const neutral50 = convertHexToRGB(black, 0.04);
export const neutral100 = convertHexToRGB(black, 0.08);
export const neutral200 = convertHexToRGB(black, 0.16);
export const neutral300 = convertHexToRGB(black, 0.24);
export const neutral400 = convertHexToRGB(black, 0.32);
export const neutral500 = convertHexToRGB(black, 0.48);
export const neutral600 = convertHexToRGB(black, 0.64);
export const neutral700 = convertHexToRGB(black, 0.72);
export const neutral800 = convertHexToRGB(black, 0.8);
export const neutral900 = convertHexToRGB(black, 0.88);

// TODO: delete, old colors
export const secondary = '#4DA090';
export const primary400 = convertHexToRGB(primary, 0.8);
export const primary100 = convertHexToRGB(primary, 0.16);
export const primary50 = convertHexToRGB(primary, 0.04);
export const almostWhite = '#F8FAFB';
export const lightBlue = '#F4F8FA';
export const darkBlue = '#24384B';
export const red = '#FF1744';
export const green = '#4DA090';
export const yellow = '#F6C251';

export const grey100 = '#F5F5F5';
export const grey200 = '#EEEEEE';

export const primaryDark = '#2B2D40'; //should be deleted at the end of the project

// TODO: Delete when unused
export const pink = '#E08DAC';
export const lightPink = convertHexToRGB(pink, 0.16);
export const grey = '#22222C';
export const grey50 = '#FAFAFA';
export const grey300 = convertHexToRGB(black, 0.24);

export enum Gradients {
  veryPoor = 'linear-gradient(90deg, #EB3658 -38.13%, #49A4BF 576.45%)',
  poor = 'linear-gradient(90deg, #EB3658 -85.53%, #49A4BF 354.77%)',
  moderate = 'linear-gradient(90deg, #EB3658 -138.55%, #49A4BF 249.6%)',
}
