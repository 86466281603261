/* eslint-disable indent */
type CalculateSummaryDataProps = {
  age: string;
  years_of_smoking: string;
  smoking_expenses: string;
  triggers: string;
  quitting_concerns: string;
};

interface ExpensesValue {
  monthly: string;
  yearly: string;
  five_years: string;
}

const calculateMonthlyExpenses = (smoking_expenses: string) => {
  if (smoking_expenses === 'less_than50') {
    const expenses: ExpensesValue = {
      monthly: '$20-50',
      yearly: '$600',
      five_years: '$3,000',
    };

    return expenses;
  } else if (smoking_expenses === '51-100') {
    const expenses: ExpensesValue = {
      monthly: '$51-100',
      yearly: '$1,200',
      five_years: '$6,000',
    };

    return expenses;
  } else if (smoking_expenses === '101-150') {
    const expenses: ExpensesValue = {
      monthly: '$101-150',
      yearly: '$1,800',
      five_years: '$9,000',
    };

    return expenses;
  } else if (smoking_expenses === '151-200') {
    const expenses: ExpensesValue = {
      monthly: '$151-200',
      yearly: '$2,400',
      five_years: '$12,000',
    };

    return expenses;
  } else if (smoking_expenses === '201_or_more') {
    const expenses: ExpensesValue = {
      monthly: '$201-600',
      yearly: '$4,800',
      five_years: '$24,000',
    };

    return expenses;
  }
};

const calculateBiologicalAge = (age: string, years_of_smoking: string) => {
  if (years_of_smoking === 'up_to_1_year') {
    return Number(age) + 2;
  } else if (years_of_smoking === '1_5') {
    return Number(age) + 4;
  } else if (years_of_smoking === '6_10') {
    return Number(age) + 7;
  } else if (years_of_smoking === '11_15') {
    return Number(age) + 9;
  } else if (years_of_smoking === '16_years_or_more') {
    return Number(age) + 10;
  }
};

const convertSmokingPeriod = (years_of_smoking: string) => {
  if (years_of_smoking === 'up_to_1_year') {
    const removedBottomLines = years_of_smoking.replaceAll('_', ' ');
    const firstUpperCase =
      removedBottomLines.charAt(0).toUpperCase() + removedBottomLines.slice(1);
    return firstUpperCase;
  } else if (years_of_smoking === '16_years_or_more') {
    return String(years_of_smoking).replaceAll('_', ' ');
  } else {
    const period = String(years_of_smoking).replace('_', '-') + ' years';
    return period;
  }
};

const findTriggers = (triggers: string) => {
  const splitedAnswer = triggers?.split('|');

  const isEmotionalTrigger = /intense_emotions/.test(splitedAnswer[0]);
  const isEmotionalTriggerSecond =
    splitedAnswer[1] && /intense_emotions/.test(splitedAnswer[1]);

  const isSocialTrigger = /parties|being_with_someone_who_smokes/.test(
    splitedAnswer[0],
  );
  const isSocialTriggerSecond =
    splitedAnswer[1] &&
    /parties|being_with_someone_who_smokes/.test(splitedAnswer[1]);

  const isHabitTrigger =
    /talking_on_the_phone_watching_tv|drinking_alcohol_coffee_finishing_a_meal|driving/.test(
      splitedAnswer[0],
    );
  const isHabitTriggerSecond =
    splitedAnswer[1] &&
    /talking_on_the_phone_watching_tv|drinking_alcohol_coffee_finishing_a_meal|driving/.test(
      splitedAnswer[1],
    );

  const isWithdrawal = /smelling_cigarette/.test(splitedAnswer[0]);
  const isWithdrawalSecond =
    splitedAnswer[1] && /smelling_cigarette/.test(splitedAnswer[1]);

  const triggerFirst = isEmotionalTrigger
    ? 'emotional'
    : isSocialTrigger
    ? 'social'
    : isHabitTrigger
    ? 'habit'
    : isWithdrawal
    ? 'withdrawal'
    : 'habit';

  const triggerSecond = isEmotionalTriggerSecond
    ? 'emotional'
    : isSocialTriggerSecond
    ? 'social'
    : isHabitTriggerSecond
    ? 'habit'
    : isWithdrawalSecond
    ? 'withdrawal'
    : 'habit';

  const triggerBlocksValues = {
    trigger_1: triggerFirst,
    trigger_2: triggerSecond,
  };

  if (
    splitedAnswer[0] &&
    splitedAnswer[1] &&
    splitedAnswer[0] !== 'none' &&
    splitedAnswer[1] !== 'none'
  ) {
    return triggerBlocksValues;
  } else if (splitedAnswer[0] !== 'none') {
    return triggerBlocksValues.trigger_1;
  } else {
    const defaultTriggers = {
      trigger_1: 'habit',
      trigger_2: 'withdrawal',
    };
    return defaultTriggers;
  }
};

export const findConcernArguments = (quitting_conerns: string) => {
  const splitedAnswer = quitting_conerns?.split('|');
  const concernArguments = {
    first: splitedAnswer[0] === 'none' ? 'miss_socializing' : splitedAnswer[0],
    second: splitedAnswer[1] ? splitedAnswer[1] : 'withdrawals',
    third: splitedAnswer[2] ? splitedAnswer[2] : 'gain_weight',
  };

  return concernArguments;
};

export const calculateSummaryData = ({
  age,
  years_of_smoking,
  smoking_expenses,
  triggers,
  quitting_concerns,
}: CalculateSummaryDataProps) => {
  const biological_age = calculateBiologicalAge(age, years_of_smoking);
  const smoking_period = convertSmokingPeriod(years_of_smoking);
  const expenses_calculations = calculateMonthlyExpenses(smoking_expenses);
  const smoking_triggers = findTriggers(triggers);
  const concern_arguments = findConcernArguments(quitting_concerns);
  return {
    biological_age,
    smoking_period,
    expenses_calculations,
    smoking_triggers,
    concern_arguments,
  };
};
