import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@kilornd/modules-wrapper/es/components';

import {
  convertHexToRGB,
  neutral100,
  neutral300,
  primary,
  white,
} from 'styles/colors';

interface Props {
  background?: string;
}

export const PrimaryButton: React.FC<Props & ButtonProps> = ({
  children,
  ...props
}) => (
  <PrimaryButtonStyled loadingColor={neutral300} {...props}>
    {children}
  </PrimaryButtonStyled>
);

const PrimaryButtonStyled = styled(Button)<Props>`
  color: ${white};
  background: ${({ background }) => background || primary};
  border: none;
  box-shadow: 0px 1rem 2rem ${convertHexToRGB(primary, 0.24)};
  border-radius: 0.5rem;

  @media (hover: hover) and (pointer: fine) {
    &:hover:enabled {
      box-shadow: none;
      background: ${({ background }) =>
        convertHexToRGB(background || primary, 0.8)};
    }
  }

  &:active:enabled {
    background: ${({ background }) => background || primary};
    box-shadow: none;
  }

  &:disabled {
    background: ${neutral100};
    box-shadow: none;
    color: ${neutral300};
  }
`;
