import React from 'react';
import styled from 'styled-components';
import { FlexWrapper, H5, Small } from '@kilornd/modules-wrapper/es/components';

import { Svg } from 'components/images/Svg';

interface Props {
  title: string;
  onClick: () => void;
  isSelected: boolean;
}

export const CollapsibleItem: React.FC<Props> = ({
  children,
  title,
  isSelected = false,
  onClick,
}) => (
  <>
    <FlexWrapperStyled selected={isSelected} onClick={onClick}>
      <H5>{title}</H5>
      <IconArrow src="expand_more" />
    </FlexWrapperStyled>
    {isSelected && <Small margin="1rem 0 0">{children}</Small>}
  </>
);

const IconArrow = styled(Svg)`
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: auto;
  transform: rotate(0deg);

  transition: transform 0.2s;
`;

const FlexWrapperStyled = styled(FlexWrapper)<{ selected?: boolean }>`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${IconArrow} {
    ${({ selected }) => selected && `transform: rotate(180deg);`};
  }
`;
