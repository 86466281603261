import { createReducer, merge, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import {
  clearAnswers,
  setCode,
  setEmail,
  setErrorMsg,
  setQuizAnswers,
  setSelectedPlan,
  setSummary,
} from './actions';
import { IUserState } from './types';

const quiz_answers = createReducer<IUserState['quiz_answers']>(
  {},
  reduce(setQuizAnswers, merge),
  reduce(clearAnswers, () => ({})),
);
const email = createReducer<IUserState['email']>(null, reduce(setEmail, set));
const summary = createReducer<IUserState['summary']>(
  null,
  reduce(setSummary, set),
);
const code = createReducer<IUserState['code']>(null, reduce(setCode, set));
const selected_plan = createReducer<IUserState['selected_plan']>(
  null,
  reduce(setSelectedPlan, set),
);

const error_msg = createReducer<IUserState['error_msg']>(
  null,
  reduce(setErrorMsg, set),
);

export default combineReducers<IUserState>({
  summary,
  code,
  quiz_answers,
  email,
  error_msg,
  selected_plan,
});
