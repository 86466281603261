import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { H2, Small } from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { useRouter } from 'apis/history';
import { Api } from 'apis/api';
import { handleError } from 'utils/error';
import { EmailForm, ModalWrapper } from 'components';
import { updateCode, updateEmail } from 'state/user/effects';
import { grey } from 'styles/colors';

const Lead = () => {
  const { goToPayments, closeModal } = useRouter();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMobile } = useQuery();

  const handleEmailFormSubmit = async (email: string) => {
    try {
      setIsLoading(true);
      const res = await Api.createLead({ email });
      const { code } = res || {};

      dispatch(updateEmail(email));
      dispatch(updateCode(code));

      setIsLoading(false);
      goToPayments();
    } catch (e: any) {
      setIsLoading(false);
      handleError(e);
      setErrorMsg(e?.response?.data?.errors?.email?.[0]);
    }
  };

  return (
    <ModalWrapper onClose={closeModal}>
      <H2
        padding={isMobile ? '0.75rem 0 1rem' : '0.75rem 0 2rem'}
        textAlign={isMobile ? 'left' : 'center'}
        fontWeight="600"
      >
        Please enter your email address to proceed
      </H2>
      <Small
        margin="0 0 2.5rem"
        textAlign={isMobile ? 'left' : 'center'}
        color={grey}
      >
        We don’t send spam or share email addresses. We respect your privacy.
      </Small>
      <EmailForm
        onSubmit={handleEmailFormSubmit}
        errorMessage={errorMsg}
        isLoading={isLoading}
        setErrorMsg={setErrorMsg}
      />
    </ModalWrapper>
  );
};

export default Lead;
