//Home

import how_method_works from 'assets/images/home/how_method_works.png';
import lp_image from 'assets/images/home/lp_image.png';
import lp_image_mobile from 'assets/images/home/lp_image_mobile.jpg';
import error_image from 'assets/images/error_image.png';

//Quiz
import info_great_news from 'assets/images/quiz/info_great_news.jpg';
import info_we_feel_you from 'assets/images/quiz/info_we_feel_you.jpg';
import start_looking_forward from 'assets/images/quiz/start_looking_forward.jpg';
import quotes from 'assets/images/quiz/quotes.png';
import cigarette from 'assets/images/quiz/cigarette.png';

//Summary
import cravings_chart from 'assets/images/summary/cravings_chart.png';
import socializing from 'assets/images/summary/socializing.png';
import laugh from 'assets/images/summary/laugh.png';
import no_weight_gain from 'assets/images/summary/no_weight_gain.png';
import enjoy from 'assets/images/summary/enjoy.png';
import results from 'assets/images/summary/results.png';
import meditate from 'assets/images/summary/meditate.png';
import how_it_works_1 from 'assets/images/summary/how_it_works_1.jpg';
import how_it_works_2 from 'assets/images/summary/how_it_works_2.jpg';
import how_it_works_3 from 'assets/images/summary/how_it_works_3.jpg';
import how_it_works_4 from 'assets/images/summary/how_it_works_4.jpg';
import author from 'assets/images/summary/author.jpg';
//mediaproof
import bbc_logo from 'assets/images/summary/bbc_logo.png';
import bi_logo from 'assets/images/summary/bi_logo.png';
import healthline_logo from 'assets/images/summary/healthline_logo.png';
import nyt_logo from 'assets/images/summary/nyt_logo.png';

//Checkout
import safe_checkout from 'assets/images/checkout/safe_checkout.png';
import change_the_way from 'assets/images/checkout/change_the_way.png';
import learn_how_to_deal from 'assets/images/checkout/learn_how_to_deal.png';
import easy_to_follow_1 from 'assets/images/checkout/easy_to_follow_1.png';
import easy_to_follow_2 from 'assets/images/checkout/easy_to_follow_2.png';
import easy_to_follow_3 from 'assets/images/checkout/easy_to_follow_3.png';

export const images: Record<string, string> = {
  bbc_logo,
  bi_logo,
  healthline_logo,
  nyt_logo,
  author,
  how_it_works_1,
  how_it_works_2,
  how_it_works_3,
  how_it_works_4,
  enjoy,
  results,
  meditate,
  laugh,
  no_weight_gain,
  socializing,
  cravings_chart,
  cigarette,
  quotes,
  info_we_feel_you,
  start_looking_forward,
  info_great_news,
  how_method_works,
  lp_image,
  lp_image_mobile,
  safe_checkout,
  change_the_way,
  learn_how_to_deal,
  easy_to_follow_1,
  easy_to_follow_2,
  easy_to_follow_3,
  error_image,
};
