import React, { memo, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { config } from '../../config';

export const withApp = (component: React.ReactNode) => {
  const WrappedComponent: React.FC = memo(() => {
    useEffect(() => {
      if (config.ENV === 'production') {
        Sentry.init({
          dsn: config.SENTRY_DNS,
          normalizeDepth: 10,
        });
        Sentry.setTag('rnd-project', config.NAME);
        Sentry.setTag('rnd-env', config.ENV);
      }
    }, []);

    return <>{component}</>;
  });
  WrappedComponent.displayName = 'WrappedComponent';

  return <WrappedComponent />;
};
