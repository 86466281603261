import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  FlexWrapper,
  GridWrapper,
  H1,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Svg } from 'components/images/Svg';
import { grey, primary, white } from 'styles/colors';
import { SecondaryButton } from 'components/buttons/SecondaryButton';

const LINKS = [
  { linkName: 'Home', linkTo: '/' },
  { linkName: 'Contacts', linkTo: '/contacts' },
  { linkName: 'Privacy Policy', linkTo: '/privacy-policy' },
  { linkName: 'Terms & Conditions', linkTo: '/terms-of-services' },
];

export const BurgerMenu: React.FC<Props> = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const { isMobile } = useQuery();

  const MenuItems = () => (
    <GridWrapperStyled
      columns={isMobile ? 1 : 5}
      gridGap="0.25rem"
      alignItems="center"
    >
      {LINKS.map((link, index) => (
        <Link key={index} to={link.linkTo} activeClassName="navigation-active">
          {isMobile ? (
            <H1
              color={grey}
              textAlign="center"
              margin={isMobile ? '0 0 1rem 0' : '0 1.5rem'}
            >
              {link.linkName}
            </H1>
          ) : (
            index !== 0 && (
              <Small color={grey} margin="0 1rem">
                {link.linkName}
              </Small>
            )
          )}
        </Link>
      ))}
      <Link to="/login">
        <SecondaryButton
          id="burger_desktop_go_to_login_link"
          data-testid="burger_desktop_go_to_login_link"
          leftIcon="perm_identity"
          leftIconStyle={{
            fill: primary,
          }}
          size={isMobile ? 'regular' : 'small'}
          maxWidth={isMobile ? '100%' : '8.69rem'}
          margin={isMobile ? '0 auto' : '0'}
        >
          Login
        </SecondaryButton>
      </Link>
    </GridWrapperStyled>
  );

  return isMobile ? (
    <FlexWrapper alignItems="center">
      <Link to="/login">
        <SecondaryButton
          id="burger_mobile_go_to_login_link"
          data-testid="burger_mobile_go_to_login_link"
          leftIcon="perm_identity"
          leftIconStyle={{
            fill: primary,
          }}
          size={isMobile ? 'extra-small' : 'small'}
          maxWidth={isMobile ? '6.3rem' : '8.69rem'}
          margin="0 1rem"
        >
          Login
        </SecondaryButton>
      </Link>
      <SvgBurger
        onClick={() => setIsOpened(!isOpened)}
        src={isOpened ? 'close' : 'burger_menu'}
        height="1.5rem"
        width="1.5rem"
      />
      {isOpened ? <MenuItems /> : null}
    </FlexWrapper>
  ) : (
    <MenuItems />
  );
};

const GridWrapperStyled = styled(GridWrapper)`
  .navigation-active {
    h1,
    p {
      color: ${primary};
    }
  }

  @media ${mobile} {
    display: block;
    position: absolute;
    top: -1.25rem;
    left: 6%;
    right: 6%;
    margin: 0 -6%;
    padding: 8rem 6%;
    height: 100vh;
    background: ${white};
    z-index: 1001;
  }
`;

const SvgBurger = styled(Svg)`
  @media ${mobile} {
    z-index: 1010;
  }
`;
