// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-downsell-info-tsx": () => import("./../../../src/pages/downsell-info.tsx" /* webpackChunkName: "component---src-pages-downsell-info-tsx" */),
  "component---src-pages-email-index-tsx": () => import("./../../../src/pages/email/index.tsx" /* webpackChunkName: "component---src-pages-email-index-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lead-index-tsx": () => import("./../../../src/pages/lead/index.tsx" /* webpackChunkName: "component---src-pages-lead-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-payments-index-tsx": () => import("./../../../src/pages/payments/index.tsx" /* webpackChunkName: "component---src-pages-payments-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quiz-index-tsx": () => import("./../../../src/pages/quiz/index.tsx" /* webpackChunkName: "component---src-pages-quiz-index-tsx" */),
  "component---src-pages-summary-index-tsx": () => import("./../../../src/pages/summary/index.tsx" /* webpackChunkName: "component---src-pages-summary-index-tsx" */),
  "component---src-pages-terms-of-services-tsx": () => import("./../../../src/pages/terms-of-services.tsx" /* webpackChunkName: "component---src-pages-terms-of-services-tsx" */)
}

