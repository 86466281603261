import React from 'react';
import { Router } from '@reach/router';

import { Route } from 'components';

import { NavigationMenuButton } from './NavigationMenuButton';
import { NavigationPlainCenter } from './NavigationPlainCenter';

export const Navigations: React.FC = () => (
  <>
    <Router primary={false}>
      {Route(
        ['/contacts', '/privacy-policy', '/terms-of-services'],
        NavigationMenuButton,
      )}
    </Router>
    <Router primary={false}>
      {Route(['/email', '/error', '/checkout/*'], NavigationPlainCenter)}
    </Router>
  </>
);
