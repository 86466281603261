import React, { useState } from 'react';
import { IProduct } from '@kilornd/analytics-wrapper/dist/types';

import { handleError } from 'utils/error';
import { KiloPayments, Loader, LoaderProcessing } from 'components';
import { useRouter } from 'apis/history';

interface Props {
  code: string;
  selectedPlan: IProduct;
}

export const PaymentsForm: React.FC<Props> = ({ code, selectedPlan }) => {
  const { goToError } = useRouter();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(
    false,
  );

  const handleSubmit = (method: string) => {
    if (method === 'card') {
      setIsPaymentProcessing(true);
    }
  };

  const handleSuccess = () => {
    setIsPaymentProcessing(false);
    goToError();
  };

  const onError = (e: any): void => {
    setIsPaymentProcessing(false);
    handleError(e);
  };

  return (
    <>
      {isPaymentProcessing && <LoaderProcessing />}
      <KiloPayments
        code={code}
        initialProduct={selectedPlan}
        handleSubmit={handleSubmit}
        handleError={onError}
        handleSuccess={handleSuccess}
      >
        <Loader />
      </KiloPayments>
    </>
  );
};
