import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Regular } from '@kilornd/modules-wrapper/es/components';

import { ModalWrapper } from 'components';
import { useRouter } from 'apis/history';
import { AppState } from 'state/types';
import { updateSelectedPlan } from 'state/user/effects';

import { OrderSummary } from './sections/OrderSummary';
import { PaymentInformation } from './sections/PaymentInformation';
import { PaymentsForm } from './sections/PaymentsForm';
import { grey100 } from 'styles/colors';

const Payments: React.FC<RouteComponentProps> = React.memo(() => {
  const dispatch = useDispatch();
  const { products } = useSelector((s: AppState) => s.funnel);
  const { selected_plan, code } = useSelector((s: AppState) => s.user);
  const { closeModal, goToLead } = useRouter();

  useEffect(() => {
    const [product] = products || [];

    if (!selected_plan?.key && product) {
      dispatch(updateSelectedPlan(product));
    }

    if (!code) {
      goToLead();
    }
  }, [selected_plan, code]);

  if (!selected_plan?.key || !code) {
    return null;
  }

  return (
    <ModalWrapper onClose={closeModal} title="Payment" background={grey100}>
      <OrderSummary selectPlan={selected_plan} />
      <Regular fontWeight={700} margin="0 0 1.5rem">
        Select a secure payment method
      </Regular>
      <PaymentsForm code={code} selectedPlan={selected_plan} />
      <PaymentInformation selectedPlan={selected_plan} />
    </ModalWrapper>
  );
});

Payments.displayName = 'Payments';

export default Payments;
