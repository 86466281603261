import React, { useState } from 'react';
import MailCheck from 'react-mailcheck';
import styled from 'styled-components';
import {
  Caption,
  Regular,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';

import { Input, PrimaryButton } from 'components';
import { IUserState } from 'state/user/types';
import { validateEmail } from 'utils/validations';
import { neutral300, textBlack } from 'styles/colors';

interface Props {
  errorMessage: IUserState['error_msg'];
  onSubmit: (email: string) => void;
  isLoading: boolean;
  setErrorMsg: (errorMsg: string) => void;
}

export const EmailForm: React.FC<Props> = ({
  errorMessage,
  isLoading,
  onSubmit,
  setErrorMsg,
}) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailAddress = email.replace(/\s/g, '');

    onSubmit(emailAddress);
  };

  const onTextUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setErrorMsg('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <MailCheck email={email}>
        {(suggestion: any) => (
          <>
            <Input
              id="email__input"
              data-testid="email__input"
              className={errorMessage && 'error'}
              placeholder="Email Address"
              onChange={onTextUpdate}
              required
              value={email}
              type="email"
              name="email"
              errorMessage={errorMessage}
              leftIcon="email"
              leftIconStyle={{
                fill: neutral300,
              }}
            />
            {suggestion && validateEmail(email) ? (
              <RegularCursor
                margin="0.5rem 0 0"
                onClick={() => {
                  setErrorMsg('');
                  setEmail(suggestion.full);
                }}
              >
                Did you mean:{' '}
                <TextWrapper fontWeight={700}>{suggestion.full}</TextWrapper>?
              </RegularCursor>
            ) : null}
          </>
        )}
      </MailCheck>
      <PrimaryButton
        id="email__submit-button"
        data-testid="email__submit-button"
        type="submit"
        isLoading={isLoading}
        margin="1rem auto 1.5rem"
        maxWidth="100%"
      >
        Continue
      </PrimaryButton>
      <Caption color={textBlack} textAlign="center">
        By continuing, you agree to get future information from us. No spam,
        though! We respect your privacy and will not sell or share your
        information with any third parties.
      </Caption>
    </Form>
  );
};

const Form = styled.form``;

const RegularCursor = styled(Regular)`
  cursor: pointer;
`;
