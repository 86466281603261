import React from 'react';
import styled from 'styled-components';
import { default as ReactSlider } from 'react-slick';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Seo } from 'components';
import { Svg } from 'components/images/Svg';
import { primaryDark, white } from 'styles/colors';

interface IArrow {
  onClick?: () => void;
}

const NextArrow = ({ onClick }: IArrow) => (
  <Next onClick={onClick} src="slider_nav_right" />
);

const PrevArrow = ({ onClick }: IArrow) => (
  <Prev onClick={onClick} src="slider_nav_left" />
);

interface ISliderSettings {
  arrows?: boolean;
}

interface ISlider {
  forwardedRef?: any;
  beforeChange?: (current: number, next: number) => void;
  sliderSettings?: ISliderSettings;
}

export const Slider: React.FC<ISlider> = ({
  children,
  forwardedRef,
  beforeChange,
  sliderSettings,
}) => {
  const arrowsSettings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: 'progressive',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange,
    ...arrowsSettings,
    ...sliderSettings,
  };

  return (
    <>
      <Seo>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/npm/react-image-lightbox@5.1.1/style.css"
        />
      </Seo>
      <Wrapper>
        <ReactSlider ref={forwardedRef} {...settings}>
          {children}
        </ReactSlider>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;

  .slick-slider .slick-dots {
    position: relative;
    bottom: 0;
    margin: 1.5rem 0 0;

    @media ${mobile} {
      margin: 1rem 0 0;
    }

    & li {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 0.25rem 0 0;
      border: 1px solid ${primaryDark};
      border-radius: 50%;

      &:last-child {
        margin: 0;
      }

      button {
        :before {
          display: none;
        }
      }

      &.slick-active {
        background: ${primaryDark};
      }
    }
  }
`;

export const SliderArrowStyle = styled(Svg)`
  position: absolute;
  height: 2rem;
  width: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  background-color: ${white};
  transform: translate(0, -50%);
  cursor: pointer;
`;

const DefaultArrow = styled(SliderArrowStyle)`
  top: 44%;
`;

const Next = styled(DefaultArrow)`
  right: -1rem;

  @media ${mobile} {
    right: -0.5rem;
  }
`;

const Prev = styled(DefaultArrow)`
  left: -1rem;
  z-index: 1;

  @media ${mobile} {
    left: -0.5rem;
  }
`;
