export const getQueryParams = () => {
  const args: { [key: string]: string | null } = {};
  const params = new URLSearchParams(window.location?.search);
  if (params.get('test')) {
    args['test'] = params.get('test');
  }
  if (params.get('flow')) {
    args['flow'] = params.get('flow');
  }
  const query = Object.keys(args)
    .map(key => `${key}=${args[key]}`)
    .join('&');
  return query ? `?${query}` : '';
};
