import { IProduct, IQuiz } from '@kilornd/api-wrapper/dist/types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Api } from '../../apis/api';
import { handleError } from '../../utils/error';
import {
  setDiscountProduct,
  setExperiments,
  setProducts,
  setQuestions,
} from './actions';
import { IExperiments } from './types';

export const fetchProducts =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    Api.fetchProducts()
      .then((products: IProduct[]) => dispatch(setProducts(products)))
      .catch(handleError);

export const fetchQuiz =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    let quiz_name = 'main';

    if (typeof window !== `undefined`) {
      quiz_name =
        new URLSearchParams(window.location?.search).get('quiz') || 'main';
    }

    Api.fetchQuiz(quiz_name)
      .then(({ questions }: IQuiz) => dispatch(setQuestions(questions)))
      .catch(error => {
        handleError(error);

        Api.fetchQuiz()
          .then(({ questions }: IQuiz) => dispatch(setQuestions(questions)))
          .catch(handleError);
      });
  };

export const updateExperiments =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    let experiments: IExperiments = {};

    if (typeof window !== `undefined`) {
      const params = new URLSearchParams(window.location?.search);
      const testId = params.get('test');
      const flow = params.get('flow');

      if (testId) {
        experiments[testId] = true;
      }

      if (flow) {
        experiments[flow] = true;
      }
    }

    dispatch(setExperiments(experiments));
  };

export const updateMailerLiteData = (code: string, data: object) => () => {
  Api.setMailerLiteData(code, data).catch(handleError);
};

export const updateDiscountProduct =
  (product: IProduct) => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setDiscountProduct(product));
