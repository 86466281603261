import React from 'react';
import styled from 'styled-components';

import { Svg } from 'components/images/Svg';

interface Props {
  margin?: string;
}

export const Logo: React.FC<Props> = ({ margin, ...props }) => (
  <Wrapper margin={margin} {...props}>
    <SvgStyled src="logotype" />
  </Wrapper>
);

const SvgStyled = styled(Svg)`
  display: block;
  width: 3.75rem;
`;

const Wrapper = styled.div<Props>`
  ${SvgStyled} {
    margin: ${({ margin }) => margin || 0};
  }
`;
