import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { Container } from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Logo } from 'components';
import { almostWhite, black, convertHexToRGB } from 'styles/colors';

export const NavigationPlainCenter: React.FC<RouteComponentProps> = () => {
  const { isMobile } = useQuery();

  return (
    <LogoWrapper>
      <Container>
        <Logo margin={isMobile ? '0' : '0 auto'} />
      </Container>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  padding: 1.25rem 0;
  box-shadow: 0 0.5rem 1.5rem ${convertHexToRGB(black, 0.04)};
  background-color: ${almostWhite};

  @media ${mobile} {
    padding: 0.75rem 0;
    box-shadow: 0 0.063rem 0 ${convertHexToRGB(black, 0.08)};
  }
`;
