import { fetchProducts, fetchQuiz, updateExperiments } from './funnel/effects';
import { fetchProjectConfig } from './config/effects';
import { fetchUser } from './user/effects';
import { AppDispatch } from './types';

export const initialFetch = (dispatch: AppDispatch): void => {
  dispatch(updateExperiments());
  dispatch(fetchProjectConfig());
  dispatch(fetchProducts());
  dispatch(fetchQuiz());
  dispatch(fetchUser());
};
