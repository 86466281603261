import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import each from 'lodash/each';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Small } from '@kilornd/modules-wrapper/es/components';

import { Analytics } from '../../apis/Analytics';
import { convertHexToRGB, grey } from 'styles/colors';

export const KiloPayments = ({
  code,
  children,
  handleSubmit,
  handleError,
  handleSuccess,
  initialProduct,
}) => {
  let IS_LOADED = false;
  const [loading, setLoading] = useState(false);
  const urlConfigs = useSelector(s => s.config?.gateway);

  const loadScript = src =>
    new Promise(resolve => {
      if (IS_LOADED) {
        resolve();
        return;
      }

      const element = document.createElement('script');
      const attributes = {
        id: 'kilo-script-' + btoa(src),
        src,
      };

      each(attributes, (value, name) => {
        if (value) {
          element[name] = value;
        }
      });

      element.onload = function () {
        IS_LOADED = true;
        resolve();
      };

      document['head'].appendChild(element);
    });

  const onSuccess = data => {
    if (typeof handleSuccess === 'function') {
      handleSuccess(data);
    }

    Analytics.trackPurchase(data.order_id, 'USD');

    return false;
  };

  const onSubmit = data => {
    if (typeof handleSubmit === 'function') {
      handleSubmit(data?.method);
    }

    const product = window.kiloCheckout.getSelectedProduct();

    Analytics.trackPaymentSubmit({
      method: data?.method,
      product: product?.title,
    });
  };

  const onError = e => {
    if (typeof handleError === 'function') {
      handleError(e);
    }
    return false;
  };

  const createUi = async () => {
    try {
      setLoading(true);
      const price = get(initialProduct, 'prices[0].final_price');
      const product = new window.KiloProduct(
        initialProduct.key,
        initialProduct.name,
        price,
      );

      if (urlConfigs.prepare_urls) {
        Object.keys(urlConfigs.prepare_urls).map(async url => {
          await window.kiloCheckout.create(urlConfigs.prepare_urls[url], {
            product,
            clientCode: code,
            selector: `#kilo-payment-${url}`,
            callbacks: {
              onError,
              onSubmit,
              onSuccess,
            },
          });
        });
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (urlConfigs?.main) {
      loadScript(urlConfigs.main).then(() => {
        createUi();
      });
    }
  }, [urlConfigs]);

  return (
    <>
      {Object.keys(urlConfigs?.prepare_urls || []).map((url, index) => (
        <>
          <div key={index} id={`kilo-payment-${url}`} />
          {Object.keys(urlConfigs.prepare_urls).length > index + 1 ? (
            <Small margin="1.5rem 0 -1.5rem">Or pay with card:</Small>
          ) : null}
        </>
      ))}
      {loading ? children : null}
      <div id="kilo-payments-error" />
    </>
  );
};

KiloPayments.propTypes = {
  children: PropTypes.any,
  code: PropTypes.string,
  handleError: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSuccess: PropTypes.func,
  initialProduct: PropTypes.object,
};

const Line = styled.div`
  height: 1px;
  flex: 1;
  background: ${convertHexToRGB(grey, 0.6)};
`;
