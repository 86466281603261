import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import {
  Caption,
  Container,
  FlexWrapper,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { NAME } from 'config';

export const FooterDefault: React.FC<RouteComponentProps> = () => {
  const { isMobile } = useQuery();

  return (
    <Wrapper>
      <Container>
        <FlexWrapperStyled justifyContent="space-between" alignItems="center">
          <SmallStyled>
            © {new Date().getFullYear()} {NAME}™. All rights reserved.
          </SmallStyled>
          <CaptionStyled margin={isMobile && '0 0 1rem'}>
            Disclaimer: Results may vary from person to person
          </CaptionStyled>
        </FlexWrapperStyled>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: transparent;
  padding: 2rem 0;
  width: 100%;
`;

const SmallStyled = styled(Small)`
  @media ${mobile} {
    order: 2;
  }
`;

const CaptionStyled = styled(Caption)`
  @media ${mobile} {
    order: 1;
  }
`;

const FlexWrapperStyled = styled(FlexWrapper)`
  position: relative;
  z-index: 1000;

  @media ${mobile} {
    flex-direction: column;
    text-align: center;
  }
`;
