import React from 'react';
import { Router } from '@reach/router';

import { Route } from 'components';
import Payments from 'pages/payments';
import Lead from 'pages/lead';

export const Modals = () => (
  <Router>
    {Route('/:page/lead', Lead)}
    {Route('/:page/payments', Payments)}
  </Router>
);
