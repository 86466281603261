import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FlexWrapper, H1 } from '@kilornd/modules-wrapper/es/components';
import { black, convertHexToRGB, lightBlue } from 'styles/colors';

import 'swiper/swiper.min.css';

interface Props {
  min: number;
  max: number;
  initialValue: number;
  onValueChange: (e: any) => void;
}

export const ScrollableNumber: React.FC<Props> = ({
  min,
  max,
  initialValue,
  onValueChange,
}) => (
  <SliderWrapper>
    <Swiper
      direction="vertical"
      slidesPerView={3}
      initialSlide={initialValue - min}
      freeModeMomentum
      freeModeSticky
      centeredSlides
      onSlideChange={onValueChange}
    >
      <SliderIndicator />
      {Array(max - min + 1)
        .fill(0)
        .map((_, i) => i + min)
        .map(n => (
          <SwiperSlide key={n}>
            <FlexWrapper justifyContent="center" alignItems="center">
              <H1>{n}</H1>
            </FlexWrapper>
          </SwiperSlide>
        ))}
    </Swiper>
  </SliderWrapper>
);

const SliderWrapper = styled.div`
  .swiper-container {
    height: 14rem;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 33%;
      z-index: 2;
    }

    &:before {
      top: 0;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0),
        10%,
        ${lightBlue} 100%
      );
    }

    &:after {
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0),
        10%,
        ${lightBlue} 100%
      );
    }
  }

  ${FlexWrapper} {
    height: 100%;
  }

  ${H1} {
    margin: 0 1.125rem;
  }
`;

const SliderIndicator = styled.span`
  position: absolute;
  left: 0;
  width: 100%;
  height: 33%;
  top: 50%;
  transform: translateY(-50%);
  border-top: 2px solid ${convertHexToRGB(black, 0.16)};
  border-bottom: 2px solid ${convertHexToRGB(black, 0.16)};
  pointer-events: none;
`;
