import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Container, FlexWrapper } from '@kilornd/modules-wrapper/es/components';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { BurgerMenu, Logo } from 'components';
import { black, convertHexToRGB } from 'styles/colors';

export const NavigationMenuButton: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <FlexWrapper alignItems="center" justifyContent="space-between">
          <LinkStyled to="/">
            <Logo />
          </LinkStyled>
          <BurgerMenu />
        </FlexWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0.75rem 0;
  box-shadow: 0 0.5rem 1.5rem ${convertHexToRGB(black, 0.04)};

  @media ${mobile} {
    padding: 0.5rem 0;
  }
`;

const LinkStyled = styled(Link)`
  display: block;
  margin: 0 1.5rem 0 0;

  @media ${mobile} {
    margin: 0;
  }
`;
