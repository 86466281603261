import React from 'react';
import styled from 'styled-components';
import { Caption, FlexWrapper } from '@kilornd/modules-wrapper/es/components';
import { IProduct } from '@kilornd/api-wrapper/dist/types';

import { primary } from 'styles/colors';
import { Image } from 'components';
import { Svg } from 'components/images/Svg';

interface Props {
  selectedPlan: IProduct;
}

export const PaymentInformation: React.FC<Props> = ({ selectedPlan }) => {
  const price = selectedPlan?.prices?.[0]?.final_price.toFixed(2);
  const isOneTimePurchase = selectedPlan?.key === 'one-time-purchase';

  return (
    <FlexWrapper margin="1.5rem 1rem 0" flexDirection="column">
      <FlexWrapper margin="0 0 1rem" alignItems="center">
        <Icon src="lock" />
        <Caption>
          Follow the instructions on the sreen to complete your purchase
          securely.
        </Caption>
      </FlexWrapper>
      <FlexWrapper margin="0 0 1rem" alignItems="center">
        <Icon src="info_circle" />
        <Caption>
          Payments are charged in USD. Payment provider fees may apply.{' '}
          {!isOneTimePurchase &&
            'Plan automatically cancels after three charges.'}
        </Caption>
      </FlexWrapper>
      <FlexWrapper margin="0 0 1rem" alignItems="center">
        <Icon src="dollar_circle" />
        <Caption>
          {isOneTimePurchase
            ? `You will be billed $${price} once. No recurring charges are applicable.`
            : `$${price} will be billed now. The rest two payments will be billed after 1 and 2 months. Plan automatically cancels after three charges.`}
        </Caption>
      </FlexWrapper>
      {!isOneTimePurchase && (
        <Caption margin="0 0 1.5rem 2.5rem">
          You may cancel your subscription at any time by dropping us an email
          at{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
        </Caption>
      )}
      <Image src="safe_checkout" />
    </FlexWrapper>
  );
};

const Icon = styled(Svg)`
  margin: 0 1rem 0 0;
  min-width: 1.5rem;
`;

const Link = styled.a`
  color: ${primary};
`;
