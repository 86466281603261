import React from 'react';
import styled from 'styled-components';
import {
  Input as DefaultInput,
  InputProps,
} from '@kilornd/modules-wrapper/es/components';

import {
  convertHexToRGB,
  neutral50,
  neutral500,
  primary,
  white,
} from 'styles/colors';

export const Input: React.FC<InputProps> = ({ ...props }) => (
  <DefaultInputStyled {...props} />
);

const DefaultInputStyled = styled(DefaultInput).attrs({
  placeholderColor: neutral500,
  placeholderSelectColor: primary,
  background: neutral50,
  borderRadius: '0.5rem',
})`
  &:hover:enabled {
    background: ${white};
    box-shadow: 0 0 0 0.0625rem ${convertHexToRGB(primary, 0.16)} inset,
      0 0 0.5rem ${convertHexToRGB(primary, 0.24)};
  }

  &:focus:enabled {
    background: ${white};
    box-shadow: 0 0 0 0.0625rem ${primary} inset,
      0 0 0.5rem ${convertHexToRGB(primary, 0.24)};
  }
`;
