import { createActionCreator } from '@reduxify/utils';

import {
  CLEAR_ANSWERS,
  SET_CODE,
  SET_EMAIL,
  SET_ERROR_MSG,
  SET_QUIZ_ANSWERS,
  SET_SELECTED_PLAN,
  SET_SUMMARY,
} from './constants';
import { IUserState } from './types';

export const setQuizAnswers = createActionCreator<IUserState['quiz_answers']>(
  SET_QUIZ_ANSWERS,
);
export const setEmail = createActionCreator<IUserState['email']>(SET_EMAIL);
export const setCode = createActionCreator<IUserState['code']>(SET_CODE);
export const setErrorMsg = createActionCreator<IUserState['error_msg']>(
  SET_ERROR_MSG,
);
export const setSelectedPlan = createActionCreator<IUserState['selected_plan']>(
  SET_SELECTED_PLAN,
);
export const setSummary = createActionCreator<IUserState['summary']>(
  SET_SUMMARY,
);

export const clearAnswers = createActionCreator(CLEAR_ANSWERS);
