import { IProduct } from '@kilornd/api-wrapper/dist/types';

export const calculateDiscountProc = (product?: IProduct | null) => {
  if (!product) return 0;
  return (
    100 -
    (100 * product?.prices?.[0]?.final_price) / product?.prices?.[0]?.price
  );
};

export const calculatePricePerWeek = (product?: IProduct) => {
  if (product?.key.includes('12-mo')) {
    return product?.prices[0]?.final_price / 53;
  } else if (product?.key.includes('6-mo')) {
    return product?.prices[0]?.final_price / 24;
  } else if (product?.key.includes('3-mo')) {
    return (product && product?.prices[0]?.final_price / 12) || 0;
  } else {
    return (product && product?.prices[0]?.final_price / 4) || 0;
  }
};

export const getMonth = (product?: IProduct) => {
  if (product?.key.includes('12-mo')) {
    return '12 months';
  } else if (product?.key.includes('6-mo')) {
    return '6 months';
  } else if (product?.key.includes('3-mo')) {
    return '3 months';
  } else {
    return 'month';
  }
};

export const getProductFinalPrice = (product?: IProduct) =>
  product?.prices[0]?.final_price || 0;

export const getProductOriginalPrice = (product?: IProduct) =>
  product?.prices?.[0]?.price || 0;

export const getProductDiscount = (product?: IProduct) =>
  product?.prices?.[0]?.discount || 0;

export const calculateOrdersTotal = (
  product: IProduct,
  upsells?: IProduct[],
) => {
  let total = product?.prices?.[0]?.final_price;

  if (upsells) {
    total = Object.values(upsells).reduce((acc, cur) => {
      const price = cur?.prices?.[0]?.final_price;
      return acc + price;
    }, total);
  }

  return total;
};

export const calculatePricePerDay = (product?: IProduct) => {
  if (product?.key.includes('3-mo')) {
    return ((product?.prices?.[0]?.final_price * 3) / 100).toFixed(2);
  } else {
    return (product?.prices?.[0]?.final_price / 100).toFixed(2);
  }
};

export const getBillingDescription = (product?: IProduct) =>
  product?.key.includes('3-mo')
    ? 'billed every month for 3 months'
    : 'one-time payment';
