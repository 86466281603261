import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

interface Props {
  from?: number;
  to?: number;
  symbol?: string;
  duration?: number;
}

export const LoaderPercentage: React.FC<Props> = ({
  from = 0,
  to = 100,
  symbol = '%',
  duration = 6000,
}) => {
  const interval = useRef<number | null>(null);
  const [display, setDisplay] = useState(from);

  useEffect(() => {
    if (interval.current) clearInterval(interval.current);

    const intervalMs = duration / (to - from);

    interval.current = setInterval(() => {
      setDisplay(val => {
        if (val >= to) {
          if (interval.current) clearInterval(interval.current);
          return val;
        }
        return val + 1;
      });
    }, intervalMs);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  return (
    <span>
      {display}
      {symbol}
    </span>
  );
};
