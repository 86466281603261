import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@kilornd/modules-wrapper/es/components';

import { black, convertHexToRGB, neutral300, primary } from 'styles/colors';

interface Props {
  background?: string;
}

export const SecondaryButton: React.FC<Props & ButtonProps> = ({
  children,
  leftIconStyle,
  ...props
}) => (
  <SecondaryButtonStyled
    loadingColor={neutral300}
    leftIconStyle={{
      filter: `drop-shadow(0px 0.25rem 0.5rem ${convertHexToRGB(black, 0.16)})`,
      ...leftIconStyle,
    }}
    {...props}
  >
    {children}
  </SecondaryButtonStyled>
);

const SecondaryButtonStyled = styled(Button)<Props>`
  color: ${primary};
  background-color: transparent;
  box-shadow: 0 0 0 0.125rem ${primary} inset;
  border: none;
  border-radius: 0.5rem;

  @media (hover: hover) and (pointer: fine) {
    &:hover:enabled {
      background: ${convertHexToRGB(primary, 0.04)};
    }
  }

  &:active:enabled {
    background: ${convertHexToRGB(primary, 0.16)};
  }

  &:disabled {
    color: ${neutral300};
    box-shadow: 0 0 0 0.125rem ${neutral300} inset;
  }
`;
