import React from 'react';
import styled from 'styled-components';
import {
  Caption,
  FlexWrapper,
  Regular,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import { IProduct } from '@kilornd/api-wrapper/dist/types';

import { neutral200, red } from 'styles/colors';
import { Svg } from 'components/images/Svg';
import { calculateDiscountProc } from 'utils/prices';

interface Props {
  selectPlan: IProduct;
}

export const OrderSummary: React.FC<Props> = ({ selectPlan }) => (
  <>
    <FlexWrapper margin="0 0 1.5rem">
      <Icon src="shopping_cart" />
      <Regular fontWeight={700}>Order summary</Regular>
    </FlexWrapper>
    <FlexWrapper margin="0 0 0.5rem" justifyContent="space-between">
      <Small>100-day quitting program</Small>
      <Small>${selectPlan?.prices?.[0]?.price.toFixed(2)}</Small>
    </FlexWrapper>
    {selectPlan?.prices?.[0]?.discount && (
      <FlexWrapper margin="0 0 0.5rem" justifyContent="space-between">
        <Small>
          Discount (-
          {calculateDiscountProc(selectPlan).toFixed(0)}
          %)
        </Small>
        <Small color={red}>
          -${selectPlan?.prices?.[0].discount.toFixed(2)}
        </Small>
      </FlexWrapper>
    )}
    <Line />
    <FlexWrapper justifyContent="space-between">
      <Regular fontWeight={700}>Total:</Regular>
      <Regular fontWeight={700}>
        ${selectPlan?.prices?.[0]?.final_price.toFixed(2)}
      </Regular>
    </FlexWrapper>
    <Caption margin="0 0 1.5rem" textAlign="right">
      {selectPlan.key === 'one-time-purchase'
        ? '(One-time payment)'
        : 'Billed every month (3 months total)'}
    </Caption>
  </>
);

const Line = styled.div`
  height: 1px;
  margin: 1.25rem 0;
  background: ${neutral200};
`;

const Icon = styled(Svg)`
  margin: 0 0.5rem 0 0;
`;
