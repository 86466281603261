import React, { useEffect } from 'react';
import LazyLoad, { forceVisible } from 'react-lazyload';
import styled, { CSSProp } from 'styled-components';
import { mobile, tablet } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { images } from '../../utils/images';

interface Styles {
  css?: CSSProp;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
}

export type IconsType = keyof typeof images;

interface Props extends Styles {
  src: IconsType;
  mobile_src?: IconsType;
  tablet_src?: IconsType;
}

const Img = styled.img<Styles>`
  display: block;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || ''};
  padding: ${props => props.padding || ''};
  margin: ${props => props.margin || ''};
  max-height: ${props => props.maxHeight || ''};
  max-width: ${props => props.maxWidth || ''};
`;

export const Image: React.FC<Props> = ({
  src,
  mobile_src,
  tablet_src,
  ...rest
}) => {
  useEffect(() => {
    forceVisible();
  }, []);

  if (!images[src]) {
    return null;
  }

  return (
    <LazyLoad height={200} once>
      <picture>
        {tablet_src && images[tablet_src] ? (
          <source media={tablet} srcSet={images[tablet_src]} />
        ) : null}
        {mobile_src && images[mobile_src] ? (
          <source media={mobile} srcSet={images[mobile_src]} />
        ) : null}
        <Img src={images[src]} alt="" {...rest} />
      </picture>
    </LazyLoad>
  );
};
