import React from 'react';
import styled from 'styled-components';

import { Spinner } from './Spinner';

export const Loader: React.FC = () => (
  <LoaderWrapper>
    <Spinner />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: center;
`;
