import storageSession from 'redux-persist/lib/storage/session';
import * as Sentry from '@sentry/react';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { AnyAction, applyMiddleware, compose, createStore, Store } from 'redux';
import { RootStateOrAny } from 'react-redux';

import { config, ENVs } from '../config';
import { rootReducer } from './reducers';

import { initialFetch } from './effects';

const persistConfig = {
  key: config.API_TOKEN,
  storage: storageSession,
  blacklist: ['config'],
};

export const configStore = (preloadedState = {}) => {
  let store: Store<any, AnyAction>;
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose)
      : compose;
  /* eslint-enable */

  if (config.ENV === ENVs.development) {
    store = createStore(
      persistedReducer,
      preloadedState,
      composeEnhancers(
        applyMiddleware(thunk as ThunkMiddleware<RootStateOrAny, AnyAction>),
      ),
    );
  } else {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();
    store = createStore(
      persistedReducer,
      compose(
        applyMiddleware(thunk as ThunkMiddleware<RootStateOrAny, AnyAction>),
        sentryReduxEnhancer,
      ),
    );
  }

  const persistor = persistStore(store, null, () => {
    store.dispatch(dispatch => {
      initialFetch(dispatch);
    });
  });

  return { store, persistor };
};
