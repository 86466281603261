import { Svg } from 'components/images/Svg';
import React from 'react';
import styled from 'styled-components';
import { Small } from '@kilornd/modules-wrapper/es/components';

import { convertHexToRGB, grey, primary, red, white } from 'styles/colors';

interface IOption {
  label?: string;
  value?: string;
}

interface Props {
  options: IOption[];
  placeholder: string;
  selectedValue?: string;
  errorMsg?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>,
  ) => void;
}

export const Select: React.FC<
  Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = ({ options, placeholder, selectedValue, errorMsg, ...rest }) => {
  const option: IOption =
    options.find((o: IOption) => o['value'] === selectedValue) || {};
  const { label, value } = option;

  return (
    <>
      <Wrapper>
        <IconArrow src="expand_more" width="1.5rem" height="1.5rem" />
        <SelectElement
          value={value || ''}
          className={errorMsg && 'error'}
          {...rest}
        >
          {!value && (
            <Option value="" disabled>
              {label || placeholder}
            </Option>
          )}
          {options.map((o: IOption, index: number) => (
            <option key={index} value={o['value']}>
              {o['label']}
            </option>
          ))}
        </SelectElement>
      </Wrapper>
      {!!errorMsg && (
        <Small margin="0.25rem 0 0" color={red}>
          {errorMsg}
        </Small>
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const IconArrow = styled(Svg)`
  position: absolute;
  top: 50%;
  right: 1rem;
  display: block;
  transform: translate(0, -50%);

  * {
    fill: ${convertHexToRGB(grey, 0.24)};
  }
`;

const SelectElement = styled.select`
  position: relative;
  width: 100%;
  padding: 15px;
  border: 1px solid transparent;
  background-color: ${convertHexToRGB(grey, 0.04)};
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ value }) => (value ? grey : convertHexToRGB(grey, 0.24))};
  outline: none;
  appearance: none;
  cursor: pointer;
  z-index: 1;

  &.error {
    padding-right: 3rem;
    color: ${red};
    background: ${convertHexToRGB(red, 0.08)};
    border: 1px solid ${red};

    ::-webkit-autofill {
      border: 1px solid ${red};
    }

    ::-webkit-input-placeholder {
      color: ${convertHexToRGB(red, 0.24)};
    }

    :not(:disabled) {
      :hover,
      :-webkit-autofill:hover {
        border: 1px solid ${red};
      }

      :focus,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        border: 1px solid ${red};
        box-shadow: none;
      }
    }
  }

  ::-webkit-autofill {
    border: 1px solid ${primary};
  }

  :not(:disabled) {
    :hover,
    :-webkit-autofill:hover {
      background: ${convertHexToRGB(grey, 0.08)};
      border: 1px solid ${primary};
    }

    :focus,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      background: ${white};
      border: 1px solid ${primary};
    }
  }

  :disabled {
    cursor: not-allowed;
  }
`;

const Option = styled.option``;
