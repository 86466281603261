import React from 'react';
import { Helmet } from 'react-helmet';

import meta from 'assets/meta.png';

const url = 'https://zerosmokes.com';

const AppTags = () => (
  <Helmet>
    {/* <link href={fav} id="favicon" rel="shortcut icon" type="image/x-icon" /> */}
    <meta
      name="facebook-domain-verification"
      content="hyk1dcoa3dys7mmptxjpgz13fxtgod"
    />
    <meta name="application-name" content="ZeroSmokes" />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta itemProp="url" content="https://zerosmokes.com" />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta
      name="msapplication-TileImage"
      content="/favicons/favicon-128x128.png"
    />
    <meta
      name="msapplication-square150x150logo"
      content="/favicons/favicon-150x150.png"
    />
    <meta
      name="msapplication-square310x310logo"
      content="/favicons/favicon-310x310.png"
    />
    <meta
      id="description"
      name="description"
      content="A behavioral science-based program that helps you fight triggers, create new routines, and enjoy life without smoking."
    />
  </Helmet>
);

const OGTags = () => (
  <Helmet>
    <meta id="og-image" property="og:image" content={url + meta} />
    <meta
      id="og-title"
      property="og:title"
      content="Quit Smoking by Removing Cravings | ZeroSmokes"
    />
    <meta
      id="og-image-secure"
      property="og:image:secure_url"
      content={url + meta}
    />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="ZeroSmokes" />
    <meta property="og:image:width" content="800" />
    <meta property="og:image:height" content="800" />
    <meta
      id="og-description"
      property="og:description"
      content="A behavioral science-based program that helps you fight triggers, create new routines, and enjoy life without smoking"
    />
  </Helmet>
);

const TwitterTags = () => (
  <Helmet>
    <meta id="twitter-image" name="twitter:image" content={url + meta} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="@ZeroSmokes" />
    <meta name="twitter:site" content="@ZeroSmokes" />
    <meta
      id="twitter-description"
      name="twitter:description"
      content="A behavioral science-based program that helps you fight triggers, create new routines, and enjoy life without smoking"
    />
  </Helmet>
);

export const MetaTags = () => (
  <>
    <AppTags />
    <OGTags />
    <TwitterTags />
  </>
);
