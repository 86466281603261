import React from 'react';

import logotype from 'assets/icons/logotype.svg';
import chevron_left from 'assets/icons/chevron_left.svg';
import burger_menu from 'assets/icons/burger_menu.svg';
import close from 'assets/icons/close.svg';
import star from 'assets/icons/star.svg';
import check from 'assets/icons/check.svg';
import expand_more from 'assets/icons/expand_more.svg';
import help_outline from 'assets/icons/help_outline.svg';
import local_offer from 'assets/icons/local_offer.svg';
import assignment_ind from 'assets/icons/assignment_ind.svg';
import event_note from 'assets/icons/event_note.svg';
import group_add from 'assets/icons/group_add.svg';
import playlist_add_check from 'assets/icons/playlist_add_check.svg';
import question_answer from 'assets/icons/question_answer.svg';
import mail from 'assets/icons/mail.svg';
import dots from 'assets/icons/dots.svg';
import dollar_circle from 'assets/icons/dollar_circle.svg';
import lock from 'assets/icons/lock.svg';
import info_circle from 'assets/icons/info_circle.svg';

//Summary
import biological_age from 'assets/icons/biological_age.svg';
import bullet from 'assets/icons/bullet.svg';
import erectile from 'assets/icons/erectile.svg';
import lungs from 'assets/icons/lungs.svg';
import pulse from 'assets/icons/pulse.svg';
import skin from 'assets/icons/skin.svg';
import smell from 'assets/icons/smell.svg';
import save_each_month from 'assets/icons/save_each_month.svg';
import save_in_year from 'assets/icons/save_in_year.svg';
import save_in_five_years from 'assets/icons/save_in_five_years.svg';
import list from 'assets/icons/list.svg';
import local_library from 'assets/icons/local_library.svg';
import people from 'assets/icons/people.svg';

export const icons: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  people,
  list,
  local_library,
  save_in_five_years,
  save_in_year,
  save_each_month,
  erectile,
  lungs,
  pulse,
  skin,
  smell,
  logotype,
  chevron_left,
  burger_menu,
  close,
  check,
  dots,
  biological_age,
  bullet,
  assignment_ind,
  event_note,
  group_add,
  playlist_add_check,
  question_answer,
  star,
  mail,
  expand_more,
  help_outline,
  local_offer,
  dollar_circle,
  lock,
  info_circle,
};
