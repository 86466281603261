import React, { useState } from 'react';

import { Regular } from '@kilornd/modules-wrapper/es/components';

import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';
import styled from 'styled-components';
import { black, convertHexToRGB, white } from 'styles/colors';

interface Props {
  text: string | JSX.Element;
}

export const Tooltip: React.FC<Props> = ({ text, children }) => {
  const [isActive, setIsActive] = useState(false);
  let timeout = 0;
  const handleTipShow = () => {
    timeout = setTimeout(() => {
      setIsActive(true);
    }, 100);
  };

  const handleTipHide = () => {
    clearInterval(timeout);
    setIsActive(false);
  };

  return (
    <TooltipWrapper
      onMouseEnter={handleTipShow}
      onMouseLeave={handleTipHide}
      onClick={() => setIsActive(!isActive)}
    >
      <Label>{children}</Label>
      {isActive && (
        <TooltipTip>
          <Regular>{text}</Regular>
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipTip = styled.div`
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  padding: 1rem;
  background: ${white};
  border-radius: 0.5rem;
  box-shadow: 0 1rem 2rem ${convertHexToRGB(black, 0.16)};
  width: 16.25rem;

  @media ${mobile} {
    transform: unset;
    right: 0;
    left: unset;
  }

  :before {
    content: '';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 0.5rem;
    margin-left: -0.5rem;
    bottom: 100%;
    border-bottom-color: ${white};

    @media ${mobile} {
      right: 0.25rem;
      left: unset;
    }
  }
`;

const Label = styled.div`
  cursor: pointer;
`;
