import React from 'react';
import styled from 'styled-components';
import { FlexWrapper, Regular } from '@kilornd/modules-wrapper/es/components';

import { Logo, Spinner } from 'components';

export const LoaderProcessing = React.memo(() => (
  <Overlay>
    <Wrapper>
      <Logo margin="0 0 2rem" />
      <Spinner size="4rem" margin="0 0 2rem" />
      <Regular margin="0 0 1rem">Please wait ...</Regular>
      <Regular>Do not close the browser</Regular>
    </Wrapper>
  </Overlay>
));

LoaderProcessing.displayName = 'LoaderProcessing';

const Wrapper = styled(FlexWrapper)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  z-index: 10000;
`;
