import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import {
  Container,
  FlexWrapper,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';

import { blue, white } from 'styles/colors';
import { Svg } from 'components';
import { AppState } from 'state/types';
import { calculateDiscountProc } from 'utils/prices';
import { useTimer } from 'hooks/use-timer';

interface Props extends RouteComponentProps {
  background?: string;
}

export const BannerDiscount: React.FC<Props> = ({ background }) => {
  const time = useTimer();
  const { discount_product } = useSelector((s: AppState) => s.funnel);

  if (!discount_product) return null;

  const discount = calculateDiscountProc(discount_product).toFixed(0);

  return (
    <Wrapper backgroundColor={background}>
      <Container>
        <FlexWrapper justifyContent="center">
          <IconOffer src="local_offer" width="1.5rem" height="1.5rem" />
          <TextWrapper color={white}>
            <TextWrapper fontWeight={600}>{discount}%</TextWrapper> discount
            only valid for{' '}
            <TextWrapper fontWeight={600}>{time.join(':')}</TextWrapper>
          </TextWrapper>
        </FlexWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ backgroundColor?: string }>`
  position: sticky;
  top: 0;
  padding: 1rem 0;
  background-color: ${({ backgroundColor }) => backgroundColor || blue};
`;

const IconOffer = styled(Svg)`
  display: block;
  margin: 0 1rem 0 0;
`;
