import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FlexWrapper, H3 } from '@kilornd/modules-wrapper/es/components';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Svg } from 'components/images/Svg';
import { convertHexToRGB, grey, white } from 'styles/colors';

interface Props {
  onClose?: () => void;
  background?: string;
  title?: string;
}

export const ModalWrapper: React.FC<Props> = ({
  children,
  onClose,
  background,
  title,
}) => {
  useEffect(() => {
    const body = document.getElementById('body');

    if (typeof window !== 'undefined') {
      body?.classList.add('disable-scrolling');
      window.scroll(0, 0);
    }

    return () => body?.classList.remove('disable-scrolling');
  }, []);

  return (
    <ModalOverlay onClick={onClose ? onClose : () => {}}>
      <ModalView
        onClick={event => event.stopPropagation()}
        background={background}
      >
        {title ? (
          <ModalHeader>
            <FlexWrapper
              justifyContent="space-between"
              alignItems="center"
              padding="1rem"
            >
              <H3>{title}</H3>
              <IconClose
                src="close"
                height="1.5rem"
                width="1.5rem"
                onClick={onClose}
              />
            </FlexWrapper>
          </ModalHeader>
        ) : null}

        <ContentWrapper padding={title ? '1.5rem 1rem' : '0 1rem 1rem'}>
          {!!onClose && !title && (
            <IconWrapper>
              <IconClose
                src="close"
                height="1.5rem"
                width="1.5rem"
                onClick={onClose}
              />
            </IconWrapper>
          )}
          {children}
        </ContentWrapper>
      </ModalView>
    </ModalOverlay>
  );
};

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${convertHexToRGB(grey, 0.6)};
  z-index: 999;
  overflow: auto;
`;

export const ModalView = styled.div<{ background?: string }>`
  position: static;
  max-width: 500px;
  width: 100%;
  max-height: calc(100% - 4rem);
  margin: 2rem auto;
  background: ${({ background }) => background ?? white};
  border-radius: 0.5rem;
  overflow: auto;
  z-index: 999;

  @media ${mobile} {
    max-height: none;
    margin: 0;
    border-radius: 0;
  }
`;

const ModalHeader = styled.div`
  background: ${white};
`;

const ContentWrapper = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding ?? '0 2rem 2rem'};
`;

const IconWrapper = styled.div`
  position: sticky;
  top: 0px;
  padding: 1rem 0px;
  background: ${white};
  text-align: right;
  z-index: 9999;
`;

const IconClose = styled(Svg)`
  cursor: pointer;
`;
