import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
}

export const Seo: React.FC<Props> = ({ title, children }) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    {children}
  </Helmet>
);
