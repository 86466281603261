import { createActionCreator } from '@reduxify/utils';

import {
  SET_DISCOUNT_PRODUCT,
  SET_EXPERIMENTS,
  SET_PRODUCTS,
  SET_QUESTIONS,
} from './constants';
import { IFunnelState } from './types';

export const setProducts = createActionCreator<IFunnelState['products']>(
  SET_PRODUCTS,
);
export const setQuestions = createActionCreator<IFunnelState['questions']>(
  SET_QUESTIONS,
);
export const setExperiments = createActionCreator<IFunnelState['experiments']>(
  SET_EXPERIMENTS,
);
export const setDiscountProduct = createActionCreator<
  IFunnelState['discount_product']
>(SET_DISCOUNT_PRODUCT);
