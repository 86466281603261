import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IClient } from '@kilornd/api-wrapper/dist/types';
import get from 'lodash/get';

import { Api } from '../../apis/api';
import { handleError } from '../../utils/error';

import {
  clearAnswers,
  setCode,
  setEmail,
  setErrorMsg,
  setQuizAnswers,
  setSelectedPlan,
  setSummary,
} from './actions';
import { IUserState } from './types';
import { AppState } from '../types';

export const updateQuizAnswers = (answers: IUserState['quiz_answers']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(setQuizAnswers(answers));

export const updateSummary = (summary: IUserState['summary']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(setSummary(summary));

export const updateEmail = (email: IUserState['email']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(setEmail(email));

export const updateCode = (code: IUserState['code']) => (
  dispatch: ThunkDispatch<{}, void, Action<IUserState['code']>>,
) => dispatch(setCode(code));

export const updateSelectedPlan = (product: IUserState['selected_plan']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => {
  const kiloPayments = document.getElementById('kilo-payments-error');
  if (
    product &&
    typeof (window as any).kiloCheckout !== 'undefined' &&
    kiloPayments
  ) {
    const price = get(product, 'prices[0].final_price');
    const kiloProduct = new (window as any).KiloProduct(
      product.key,
      product.name,
      price,
    );
    (window as any).kiloCheckout.selectProduct(kiloProduct);
  }
  dispatch(setSelectedPlan(product));
};

export const updateErrorMsg = (errorMsg: IUserState['error_msg']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(setErrorMsg(errorMsg));

export const fetchUser = () => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const codeParam = window.location.pathname?.split('/')?.pop();
    const pathCode = params.get('code') || codeParam;

    if (pathCode && pathCode.length === 32) {
      Api.fetchLead(pathCode)
        .then(({ email, code, quiz_answers }: IClient) => {
          if (email) {
            dispatch(updateEmail(email));
          }

          if (quiz_answers) {
            dispatch(updateQuizAnswers(quiz_answers));
          }

          dispatch(updateCode(code));
        })
        .catch(handleError);
    }
  }
};

export const clearUserAnswers = () => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(clearAnswers());
