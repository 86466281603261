import { useEffect, useState } from 'react';

export const useTimer = (seconds = 900) => {
  const [time, setTime] = useState<Array<string | number>>(['--', '--', '--']);

  useEffect(() => {
    let time: number = seconds;

    const timer = setInterval(() => {
      let minutes: string | number = parseInt(`${time / 60}`, 10);
      let seconds: string | number = parseInt(`${time % 60}`, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      setTime(['00', minutes, seconds]);

      time--;

      if (time < 0) {
        return () => clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return time;
};
